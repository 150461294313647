<template>
  <div class="building-info-page">
    <div class="content-t">
      <div class="building-info-title-box">
        <m-page-title title="Building Information" address />
        <!-- <div class="share-box">
          <img src="@/static/icon/tools/Download.png" alt="" />
          <img src="@/static/icon/tools/Link.png" alt="" />
          <img src="@/static/icon/tools/Email.png" alt="" />
          <img src="@/static/icon/tools/Print.png" alt="" />
        </div> -->
      </div>
    </div>
    <div class="content">
      <div class="content-l">
        <span class="label-txt">Search</span>
        <div class="flex-row a-center">
          <el-input size="medium" v-model="searchTxt" @input="searchFun">
            <i
              slot="suffix"
              class="el-input__icon el-icon-search g-pointer px-1"
            />
          </el-input>
          <i
            v-if="collapseIndex !== ''"
            class="el-icon-full-screen g-pointer"
            @click="screenShow = true"
          ></i>
        </div>

        <div class="info-box g-radius-4 flex-1">
          <div class="wrap g-scroll-style">
            <el-collapse accordion v-model="collapseIndex">
              <el-collapse-item
                v-for="(item, index) in announcementList"
                :key="index"
                class="el-collapse-item"
                :name="index"
                :title="item.title"
              >
                <div v-if="item.pdfParamList.length > 0">
                  <div
                    class="collapse-title g-radius-4"
                    v-for="(citem, cindex) in item.pdfParamList"
                    :key="cindex"
                  >
                    {{ citem.name }}
                    <div class="btn-box">
                      <img
                        src="@/static/icon/tools/Download.png"
                        alt=""
                        @click="fileLinkToStreamDownload(citem.url, citem.name)"
                      />
                      <a :href="citem.url" target="_blank"
                        ><img src="@/static/icon/tools/Link.png" alt=""
                      /></a>
                    </div>
                  </div>
                  <!--<div v-html="item.content">todo:暂无测试数据</div>-->
                </div>
                <div class="ql-container ql-snow">
                  <div class="ql-editor" v-html="item.content"></div>
                </div>
                <!-- <div v-else>todo: No test data at present</div> -->
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div class="content-r g-radius-4 g-shadow" id="map"></div>
    </div>
    <div class="content-b">
      <el-form
        ref="From"
        :rules="rules"
        :model="form"
        v-if="sideBarStatus !== -1"
        @keyup.enter.native="toNoticeBoard"
        @submit.native.prevent
      >
        <el-form-item prop="check">
          <el-checkbox class="check" v-model="form.check">
            I have read the building information
          </el-checkbox>
        </el-form-item>
      </el-form>

      <div class="btn-box" v-if="sideBarStatus === -1">
        <!-- <m-back /> -->
        <m-to-dashboard class="btn" />
      </div>
      <div class="btn-box" v-else>
        <m-back />
        <el-button class="btn" type="primary" @click="toNoticeBoard"
          >Proceed to notice board
        </el-button>
      </div>
    </div>

    <el-dialog :visible.sync="screenShow" class="big-collapse" fullscreen>
      <template slot="title">
        <h1 class="title-txt">Building information</h1>
        <p class="title-cont">{{ addressStr }}</p>
      </template>
      <div
        v-for="(item, index) in announcementList"
        :key="index"
        class="el-collapse-item"
      >
        <!-- <div class="collapse-tittle">{{item.title}}</div> -->
        <div v-if="item.pdfParamList.length > 0">
          <div
            class="collapse-title g-radius-4"
            v-for="(citem, cindex) in item.pdfParamList"
            :key="cindex"
          >
            {{ citem.name }}
            <div class="btn-box">
              <img
                src="@/static/icon/tools/Download.png"
                alt=""
                @click="fileLinkToStreamDownload(citem.url, citem.name)"
              />
              <a :href="citem.url" target="_blank"
                ><img src="@/static/icon/tools/Link.png" alt=""
              /></a>
            </div>
          </div>
          <!--<div v-html="item.content">todo:暂无测试数据</div>-->
        </div>
        <!-- <div v-else>todo: No test data at present</div> -->
        <div class="ql-container ql-snow">
          <div class="ql-editor" v-html="item.content"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import validate from "@/utils/validateForm";

import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "buildingInfo",
  components: {},
  data() {
    return {
      form: { check: false },
      rules: { check: [{ validator: validate.agreementConfirm }] },
      screenShow: false,
      searchTxt: "",
      collapseIndex: "",
      announcementList: [],
    };
  },
  computed: {
    ...mapGetters("apartment", ["addressStr"]),
    ...mapState("apartment", ["room", "apartment"]),
    ...mapState("bar", ["sideBarStatus"]),
  },
  filters: {
    // todo 暂时解决PDF为空的问题,后续需要跟加具体
    pdfName(e) {
      try {
        return e.pdfParamList[0].name;
      } catch {
        return "null";
      }
    },
  },
  created() {
    this.getList();
    this.keyupSubmit();
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.sideBarStatus !== -1) {
            this.toNoticeBoard();
          }
        }
      };
    },
    fileLinkToStreamDownload(url, name) {
      const fileName = name;
      const xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      const that = this;
      xhr.onload = function () {
        if (this.status === 200) {
          // 接受二进制文件流
          const blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(blob); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = tagFileName; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放blob对象
    },
    initMap() {
      const that = this;
      const loader = new Loader({
        apiKey: this.$api.googleMap.apiKey,
        version: "weekly",
      });
      loader.load().then(() => {
        // eslint-disable-next-line no-undef
        const map = new google.maps.Map(document.getElementById("map"), {
          center: {
            lat: that.apartment.latitude || -37.835894,
            lng: that.apartment.longitude || 144.9732662,
          },
          zoom: 18,
          minZoom: 14,
          mapTypeControl: false,
          streetViewControl: false,
          lang: "en",
        });
        if (that.apartment.latitude && that.apartment.longitude) {
          // eslint-disable-next-line no-undef
          const infoWindow = new google.maps.InfoWindow();
          // eslint-disable-next-line no-unused-vars,no-undef
          const marker = new google.maps.Marker({
            position: {
              lat: that.apartment.latitude,
              lng: that.apartment.longitude,
            },
            map,
            title: `${that.addressStr}`,
          });
          marker.addListener("click", () => {
            infoWindow.close();
            infoWindow.setContent(marker.getTitle());
            infoWindow.open(marker.getMap(), marker);
          });
        }
      });
    },
    searchFun() {
      this.getList();
    },
    getList() {
      const url = `${this.$api.informations(
        this.room.apartmentId
      )}?apartmentUnitId=${this.room.apartmentUnitId}&&title=${this.searchTxt}`;
      this.$axios.get(url).then((res) => {
        if (res.code === "1000")
          this.announcementList.splice(
            0,
            this.announcementList.length,
            ...res.data.list
          );
      });
    },
    toNoticeBoard() {
      this.$refs.From.validate().then(() => this.$router.push("/noticeBoard"));
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
